input[type="submit"],
select,
button {
  -webkit-appearance: none;
}

@keyframes leftToRight {
  from {
    transform: translateX(-200%);
  }
  to {
    transform: translateX(0);
  }
}

.message {
  position: absolute;
  width: 80%;
}

.form {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  width: 90%;
  max-width: 500px; /* Hace el formulario más angosto */
  border-radius: 12px;
  padding: 2rem;
  margin: auto;
  margin-top: 5%;
  animation: leftToRight 1s ease;
  font-family: "Poppins", sans-serif;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
}

.contact-form {
  display: flex;
  flex-direction: column;
  font-weight: 600;
}

.form h2 {
  color: #064273;
  font-size: 2vw;
  font-weight: bold;
  margin-bottom: 1rem;
  text-align: center;
}

input,
select {
  padding: 12px;
  height: 3rem;
  font-size: 1rem;
  background-color: #ffffff;
  border-radius: 8px;
  border: 1px solid #ccc;
  margin-top: 0.5rem;
}

::placeholder {
  font-size: 1rem;
  font-weight: 400;
}

label {
  color: #3d3d3d;
  font-size: 1rem;
  font-weight: 500;
  margin-top: 0.5rem;
}

button[type="submit"] {
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  font-weight: 700;
  font-size: 1rem;
  background-color: #fdca02;
  color: #000;
  border-radius: 8px;
  border: 0;
  height: 3rem;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

button[type="submit"]:hover {
  background-color: #e0b200;
}

button[type="submit"]:active {
  background-color: #c49b00;
  transform: scale(0.98);
}

.checkForm {
  display: flex;
  align-items: center;
  gap: 10px;
}

.conditions {
  display: flex;
  align-items: center;
}

.checkbox-container {
  display: flex;
  align-items: center;
}

input[type="checkbox"] {
  width: 20px;
  height: 20px;
}

.popup {
  width: 90%;
  max-width: 400px;
  background-color: #555555;
  color: #ffffff;
  text-align: center;
  border-radius: 6px;
  position: absolute;
  z-index: 100;
  bottom: 20%;
  padding: 1rem;
  overflow-y: auto;
}

.popuptext {
  display: none;
}

.popuptext-show {
  display: flex;
}

/* Responsivo */
@media screen and (min-width: 1001px) {
  .form {
    max-width: 600px;
    margin-top: 1px;
    margin-bottom: 60px;
  }

  .form h2 {
    font-size: 1.5rem;
  }

  input,
  select {
    height: 2.5rem;
    font-size: 1rem;
  }

  ::placeholder {
    font-size: 0.9rem;
  }

  label {
    font-size: 1rem;
  }

  button[type="submit"] {
    font-size: 1rem;
    font-weight: 700;
    height: 3rem;
  }

  .popup {
    width: 80%;
    max-width: 350px;
    font-size: 1rem;
  }
}
