@import url(https://fonts.googleapis.com/css?family=Montserrat);
a,
div,
button,
[type="button"] {
  -webkit-appearance: none;
}

.floating-button {
  display: grid;
  grid-template-columns: 10% 90%;
  grid-template-rows: 1fr;
  position: fixed;
  top: 3vw;
  right: 5%;
  background-color: #ffffff;
  border-radius: 50px;
  width: 45%;
  height: 10vw;
  text-align: center;
  align-content: center;
  flex-direction: column;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.5));
  cursor: pointer;
  z-index: 100000;
}

.floating-button a {
  position: relative;
  color: #000000;
  font-size: 4vw;
  text-align: center;
  text-decoration: none;
  align-self: center;
}

.floating-button a:hover {
  color: #000000;
  text-decoration: none;
}

.phoneIcon {
  display: grid;
  width: 7vw;
  grid-column: 1;
  grid-row: 1;
  align-self: center;
  margin-left: 30%;
  filter: invert();
}

@media screen and (min-width: 1001px) {
  .floating-button {
    width: 22vw;
    height: 3vw;
    right: 5%;
    top: 1.5vw;
  }

  .floating-button a {
    font-size: 1.3vw;
  }

  .phoneIcon {
    width: 2.5vw;
  }
}

.logo {
    width: -webkit-max-content;
    width: max-content;
    z-index: 999999;
    justify-self: left;
}

.logo img {
    margin: auto;
    width: 25vw;
}

@media screen and (min-width:1001px) {
    .logo {
        margin-top: 0%;
    }

    .logo img {
        display: block;
        margin: auto;
        width: 10vw;
    }
}
.slider-title {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 0.3fr 1fr;
  font-family: "Poppins";
}

.slider-title h2 {
  text-align: center;
  font-weight: 500;
  grid-column: 1/1;
  grid-row: 1/1;
}


.slider-products-title {
  margin-top: 4vw;
  font-size: 4.5vw;
}

.slider-services-title {
  margin-top: 4vw;
  font-size: 5vw;
}

.slider-promos-title {
  margin-top: 6vw;
  font-size: 5vw;
}

.slider-title h3 {
  text-align: center;
  font-size: 4vw;
  grid-column: 1/1;
  grid-row: 1/1;
  margin-top: 12vw;
}

.carousel {
  grid-column: 1/1;
  grid-row: 2/2;
  width: 100%;
  margin-top: -8vw;
}

@media screen and (min-width: 1001px) {
  .slider-title {
    display: flex;
    flex-direction: column;
  }

  .slider-title h2 {
    display: none;
  }

  .slider-title h3 {
    margin-top: 1vw;
    font-size: 2vw;
    font-weight: 500;
    max-width: -webkit-max-content;
    max-width: max-content;
    align-self: center;
    font-family: "Roboto";
    color: #022e50;
  }

  .carousel {
    width: 100%;
    align-self: center;
    margin-top: 0.2vw;
  }
}

input[type="submit"],
select,
button {
  -webkit-appearance: none;
}

@keyframes leftToRight {
  from {
    transform: translateX(-200%);
  }
  to {
    transform: translateX(0);
  }
}

.message {
  position: absolute;
  width: 80%;
}

.form {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  width: 90%;
  max-width: 500px; /* Hace el formulario más angosto */
  border-radius: 12px;
  padding: 2rem;
  margin: auto;
  margin-top: 5%;
  animation: leftToRight 1s ease;
  font-family: "Poppins", sans-serif;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
}

.contact-form {
  display: flex;
  flex-direction: column;
  font-weight: 600;
}

.form h2 {
  color: #064273;
  font-size: 2vw;
  font-weight: bold;
  margin-bottom: 1rem;
  text-align: center;
}

input,
select {
  padding: 12px;
  height: 3rem;
  font-size: 1rem;
  background-color: #ffffff;
  border-radius: 8px;
  border: 1px solid #ccc;
  margin-top: 0.5rem;
}

::placeholder {
  font-size: 1rem;
  font-weight: 400;
}

label {
  color: #3d3d3d;
  font-size: 1rem;
  font-weight: 500;
  margin-top: 0.5rem;
}

button[type="submit"] {
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  font-weight: 700;
  font-size: 1rem;
  background-color: #fdca02;
  color: #000;
  border-radius: 8px;
  border: 0;
  height: 3rem;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

button[type="submit"]:hover {
  background-color: #e0b200;
}

button[type="submit"]:active {
  background-color: #c49b00;
  transform: scale(0.98);
}

.checkForm {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}

.conditions {
  display: flex;
  align-items: center;
}

.checkbox-container {
  display: flex;
  align-items: center;
}

input[type="checkbox"] {
  width: 20px;
  height: 20px;
}

.popup {
  width: 90%;
  max-width: 400px;
  background-color: #555555;
  color: #ffffff;
  text-align: center;
  border-radius: 6px;
  position: absolute;
  z-index: 100;
  bottom: 20%;
  padding: 1rem;
  overflow-y: auto;
}

.popuptext {
  display: none;
}

.popuptext-show {
  display: flex;
}

/* Responsivo */
@media screen and (min-width: 1001px) {
  .form {
    max-width: 600px;
    margin-top: 1px;
    margin-bottom: 60px;
  }

  .form h2 {
    font-size: 1.5rem;
  }

  input,
  select {
    height: 2.5rem;
    font-size: 1rem;
  }

  ::placeholder {
    font-size: 0.9rem;
  }

  label {
    font-size: 1rem;
  }

  button[type="submit"] {
    font-size: 1rem;
    font-weight: 700;
    height: 3rem;
  }

  .popup {
    width: 80%;
    max-width: 350px;
    font-size: 1rem;
  }
}

/*custom font*/

/*basic reset*/
* {
    margin: 0;
    padding: 0;
}

.row{
    width:100%

}



html {
    height: 100%;
    width: 100%;
    background: #fff; /* fallback for old browsers */
}

body {
    font-family: montserrat, arial, verdana;
    background: transparent;
}

h1{
  font-family: "Poppins";
  color: #000000;
  width: 92%;
  font-weight: 400;
  line-height: 9vw;
  text-align: left;
  font-size: 3.25vh;
  margin-left: 7%;
}

h4{
    margin-top: 52vw;
    font-family: "Poppins";
    color: #000000;
    font-weight: 700;
    line-height:auto;
    font-size: 15vw;
    text-align: center;
}

h5{
    font-family: "Poppins";
    color: #000000;
    font-weight: 200;
    line-height: auto;
    text-align: left;
    font-size: 9vw;
    padding-left: 13vw;
    padding-right: 13vw;
}

h6{
    font-family: "Poppins";
    color: #000000;
    font-weight: 600;
    font-size: 6vw;
    text-align: left;
    padding-left: 13vw;
    padding-right: 13vw;
}

/*form styles*/
#msform {
  margin: 30px auto;
  text-align: center;
  width: 100%;
}


#msform fieldset {
    border: 0 none;
    border-radius: 20px;
    padding: 20px 00px;
    box-sizing: border-box;
    width: 85%;
    margin: 0 7.5%;

    /*stacking fieldsets above each other*/
    position: relative;
}

/*Hide all except first fieldset*/
#msform fieldset:not(:first-of-type) {
    display: none;
}

/*inputs*/
#msform input, #msform textarea {
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 35px;
    margin-bottom: 10px;
    width: 100%;
    box-sizing: border-box;
    font-family: montserrat;
    color: #2C3E50;
    font-size: 3vw;
}

#msform .error-text{
    border: 1px solid red;
    color: red;
    font-weight: bold;
}

#msform input:focus, #msform textarea:focus {
    box-shadow: none !important;
    border: 1px solid #FFD300;
    outline-width: 0;
    transition: All 0.5s ease-in;
    -webkit-transition: All 0.5s ease-in;
    -moz-transition: All 0.5s ease-in;
    -o-transition: All 0.5s ease-in;
}

/*buttons*/
#msform .action-button {
    width: 100%;
    height: 11vw;
    background: #FFD300;
    font-weight: bold;
    color: black;
    border: 0 none;
    border-radius: 25vw;
    cursor: pointer;
    margin-right: 5%;
    margin-top: 0%;
}

#msform .action-button:hover, #msform .action-button:focus {
    box-shadow: 0 0 0 2px white, 0 0 0 3px #FFD300;
    background: #454545;
    color: white;

}

#msform .action-button-previous:hover, #msform .action-button-previous:focus {
    box-shadow: 0 0 0 2px white, 0 0 0 3px #C5C5F1;
}

/*headings*/
.fs-title {
    color: #000000;
    font-family: "Poppins";
    font-size: 20px;
    font-weight: 800;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: center;
}

.fs-subtitle {
    color: #000000;
    font-size: 15px;
    text-align: center;
    margin-bottom: 7%;
}

#msform p {
    color: #000000;
    font-family: "Poppins";
    font-size: 60%;
    font-weight: 200;
    letter-spacing: 0em;
    margin: -0.5vw 0 0.5vw 0;
    text-align: justify;
}

/*progressbar*/
#progressbar {
    margin-top: 5vw;
    margin-bottom: 1vw;
    overflow: hidden;
    /*CSS counters to number the steps*/
    counter-reset: step;
    margin-left: 3%;
    width: 100%;
}

#progressbar li {
    list-style-type: none;
    color: white;
    text-transform: uppercase;
    font-size: 9px;
    width: 14%;
    float: left;
    position: relative;
    letter-spacing: 1px;
}

#progressbar li:before {
    content: counter(step);
    counter-increment: step;
    width: 24px;
    height: 24px;
    display: block;
    font-size: 12px;
    color: white;
    background: #070707;
    border: 2px solid none;
    border-radius: 25px;
    margin: 0 auto 10px auto;
}

/*progressbar connectors*/
#progressbar li:after {
    content: '';
    width: 100%;
    height: 4px;
    background: #070707;
    position: absolute;
    left: -50%;
    top: 9px;
    z-index: -1; /*put it behind the numbers*/
}

#progressbar li:first-child:after {
    /*connector not needed before the first step*/
    content: none;
}

/*marking active/completed steps green*/
/*The number of the step and the connector before it = green*/
#progressbar li.active:before {
    background: #FFD300;
    color: black;
}

#progressbar li.active:after{
    background: #070707;
    color: white;
}


/* Not relevant to this form */
.dme_link {
    margin-top: 30px;
    text-align: center;
}
.dme_link a {
    background: #FFF;
    font-weight: bold;
    color: #FFD300;
    border: 0 none;
    border-radius: 25px;
    cursor: pointer;
    padding: 5px 25px;
    font-size: 12px;
}

.dme_link a:hover, .dme_link a:focus {
    background: #000000;
    text-decoration: none;
}

.step {
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
  }
  
  /* Define different styles for different steps */
  .step.step-0 {
    opacity: 1;
    transform: scale(1);
  }
  
  .step.step-1 {
    opacity: 0;
    transform: scale(0.8);
  }
  
  .step.step-2 {
    opacity: 0;
    transform: scale(0.8);
  }

  .final-buttons{
    justify-content: center;
    width:100%;
    margin-top: 5%;
  }

  .text-input-container{
    margin: 0vw;
  }

  .selection-container-type-property label{
    margin-bottom: 3%;
    
}

.selection-container label{
    margin-bottom: 3%;
        margin-bottom: 3%;
    text-align: center;
    width: auto;
    min-width: 120px; /* Asegura un ancho mínimo */
}

@media screen and (min-width: 1001px) {

    /*headings*/
    .fs-title {
        font-size: 3.5vh;
        font-weight: 800;
        line-height: 35px;
        letter-spacing: 0rem;
        text-align: center;
    }

    .fs-subtitle {
        font-size: 3.5vh;
        text-align: center;
        margin-bottom: 4%;
    }

    .fs-title-end {
        font-size: 5.5vh;
        font-weight: 800;
        line-height: 35px;
        letter-spacing: 0rem;
        text-align: center;
    }

    /*form styles*/
    #msform {
        margin-top: 2vw;
        margin-left: 2%;
        width: 150%;
    }

    #msform p {
        font-size: 1vh;
    }

.selection-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 2% auto;
    width: 100%;
    max-width: 600px;
}

.selection-container-type-property {
    display: flex;
    flex-direction: row;
    justify-content: center; /* Cambiado de left a center */
    margin: 2% auto; /* Añadido auto para centrar horizontalmente */
    max-width: 600px; /* Ancho máximo para mantener proporción */
}

.selection-container-type-property span {
    display: flex;
    flex-direction: row;
    justify-content: center; /* Cambiado de space-around a center */
    margin: 0 5%; /* Margen simétrico */
}

/* Para pantallas pequeñas */
@media (max-width: 768px) {
    .selection-container,
    .selection-container-type-property {
        flex-direction: column;
        align-items: center;
        grid-gap: 15px;
        gap: 15px;
    }
}

    .text-input-container{
        margin-top: 3vw;
        align-self: center;
        margin-left: 20%;
        margin-right: 25%;
    }
    
        .final-buttons{
            margin-top: 0vw;
            font-size: 1.5vw;
        }
    
    #msform .action-button {
        width: 100%;
        height: 4vw;
        border-radius: 25vw;
        margin-right: 5%;
        margin-top: 5%;
        align-items: center;
    }

    .back-button-container{
        display: flex;
        justify-content: left;
    }

    h1{
        width: 100%;
        font-size: 5.5vh;
        margin-left: 0%;
        margin-right: 0%;
        padding-right: 0%;
        padding-left: 0%;
        font-weight: 700;
        line-height: 4vw;
        text-align: center;
    }

    /*inputs*/
    #msform input, #msform textarea {
        font-size: 1.5vw;
        height: 3.5vw;
        padding-left: 3%;
    }
    
    h4{
        margin-top: 15%;
        padding-left: 0vw;
        padding-right: 0vw;
        font-size: 8vw;
    }
    
    h5{
        line-height: 3.5vw;
        font-size: 3vw;
        text-align: center;
    }
    
    h6{
        font-size: 2vw;
        text-align: center;
    }
    
    #progressbar {
        margin-top: 4vw;
        margin-bottom: 1vw;
        overflow: hidden;
        display: flex;
        justify-content: center;
        /*CSS counters to number the steps*/
        counter-reset: step;
        margin-left: 0%;
        width: 100%;
    }
    
    #progressbar li:before {
        width: 54px;
        height: 54px;
        font-size: 32px;
    }
    
    #progressbar li:after {
        content: '';
        width: 100%;
        height: 4px;
        position: absolute;
        left: -50%;
        top: 25px;
        z-index: -1; /*put it behind the numbers*/
    }
    
}
/* Contenedor de cada opción */
.selection_radio {
  display: flex;
  align-items: center;
  justify-content: center; /* Centra el contenido */
  grid-gap: 0.5vw;
  gap: 0.5vw; /* Espacio entre el ícono y el texto */
  cursor: pointer;
  font-size: 1vw; /* Reducimos el tamaño del texto */
  font-family: "Poppins", sans-serif;
  height: 4vw; /* Tamaño fijo del botón */
  width: 10vw; /* Ancho fijo del botón */
  min-width: 10vw; /* Asegura un ancho mínimo */
  max-width: 10vw; /* Asegura que no crezca */
  padding: 0 1vw; /* Espaciado interno */
  text-align: center;
  background-color: #FFD300;
  border-radius: 50vw; /* Botón redondeado */
  transition: all 0.3s ease;
  overflow: hidden; /* Evita que el texto sobresalga */
  white-space: nowrap; /* Evita que el texto haga saltos de línea */
  text-overflow: ellipsis; /* Si el texto es muy largo, lo corta */
  margin: 0.5vw;
}

/* Evita que el input afecte el diseño */
.selection_radio input {
  display: none;
}

/* Círculo del radio button */
.checkmark {
  position: relative;
  width: 1.8vw;
  height: 1.8vw;
  background-color: #ffffff;
  border-radius: 50%;
}

/* Cambio de color cuando está seleccionado */
.selection_radio input:checked ~ .checkmark {
  background-color: #fff;
}

/* Indicador dentro del botón */
.checkmark:after {
  content: "";
  display: none;
  position: absolute;
  width: 1.3vw;
  height: 1.3vw;
  border-radius: 50%;
  background: #000;
  top: 0.25vw;
  left: 0.25vw;
}

/* Mostrar el indicador cuando está seleccionado */
.selection_radio input:checked ~ .checkmark:after {
  display: block;
}

/* Ajuste del texto */
.selection_radio span {
  font-size: 0.9vw; /* Reducimos el tamaño del texto */
  font-weight: 600;
  text-align: center;
  white-space: nowrap; /* Evita saltos de línea */
  overflow: hidden; /* Evita que el texto sobresalga */
  text-overflow: ellipsis; /* Si el texto es muy largo, lo corta */
  width: 100%;
}

/* ✅ Asegurar que todos los botones tengan el mismo tamaño */
.selection-container {
  display: flex;
  justify-content: center;
  grid-gap: 1vw;
  gap: 1vw;
}

.radio-h3 {
  font-size: 1.3vw; /* Reducir el tamaño de la letra */
  font-weight: 600; /* Ajustar el peso de la fuente */
  margin: 0; /* Eliminar márgenes extra */
  padding: 0;
  text-align: center;
}

/* Responsive - Móviles */
@media screen and (max-width: 768px) {
  .selection_radio {
    font-size: 3vw; /* Reduce el tamaño en móviles */
    height: 8vw;
    width: 12vw; /* Ancho fijo en móviles */
    min-width: 12vw;
    max-width: 12vw;
    padding: 0 2vw;
  }

  .checkmark {
    width: 4vw;
    height: 4vw;
  }

  .checkmark:after {
    width: 2.5vw;
    height: 2.5vw;
    top: 0.75vw;
    left: 0.75vw;
  }

  .selection_radio span {
    font-size: 2vw; /* Reduce aún más el texto en móviles */
  }
}

#msform select {
    width: 100%;
    height: 10vw;
    border-width: 0;
    border-radius: 35vw;
    border: 1px solid #ccc;
    padding-left: 3%;
    padding-right: 4vw;
    font-size: 1.5vh;
    font-family: "Poppins";
    background: url("data:image/svg+xml,%3Csvg width='26' height='6.5' viewBox='0 0 26 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13 13L0.875645 0.25L25.1244 0.249998L13 13Z' fill='%23080808'/%3E%3C/svg%3E") no-repeat 100% 50%;
    background-color: #ffffff;
    -webkit-appearance: none;
            appearance: none;
    background-position: right 2vw center;
}

@media screen and (min-width: 1001px) {
    #msform select {
        height: 4vw;
        width: 100%;
        padding-left: 1.5vw;
        font-size: 2.75vh;
        background: url("data:image/svg+xml,%3Csvg width='26' height='13' viewBox='0 0 26 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13 13L0.875645 0.25L25.1244 0.249998L13 13Z' fill='%23080808'/%3E%3C/svg%3E") no-repeat 95% 50%;
    }

    .dropdown-container{
        display: flex;
        justify-content: left;
    }
}

.security {
  background-color: #fdca02;
  text-align: center;
  font-family: "Pontano Sans", sans-serif;
  padding: 6vw 4vw 10vw 4vw;
}

.security h2 {
  font-size: 6vw;
  margin-bottom: 10vw;
}

.security-images {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  grid-row-gap: 5vw;
  row-gap: 5vw;
}
.security button {
  background-color: #c42a20;
  font-family: "Roboto";
  color: #ffffff;
  margin-top: 5vw;
  margin-bottom: 1vw;
  font-weight: 600;
  width: 70%;
  border: 0;
  border-radius: 50px;
  padding: 1vw;
  height: 10vw;
  font-size: 4.5vw;
  filter: drop-shadow(0px 4px 3px rgb(0, 0, 0, 0.5));
}
.security button:active {
  margin-top: 5.3vw;
  filter: drop-shadow(0px 0px 0px rgb(0, 0, 0));
  margin-bottom: 0.7vw;
}

@media screen and (min-width: 450px) {
  .security-images img {
    width: 40%;
  }
}

@media screen and (min-width: 1001px) {
  .security {
    padding: 6vw 4vw 6vw 4vw;
  }

  .security h2 {
    font-size: 3vw;
  }

  .security-images img {
    width: 20vw;
  }
  .security button {
    font-size: 2vw;
    width: 30%;
    height: 5vw;
  }

  .security button:active {
    margin-top: 5.3vw;
    filter: drop-shadow(0px 0px 0px rgb(0, 0, 0));
  }
}

.services {
    text-align: center;
    margin-top: 2rem;
}

.services h2 {
    display: inline;
    font-family: 'Pontano Sans', sans-serif;
    font-size: 8vw;
}

.items-container {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
}

.item {
    display: flex;
    flex-direction: row;
    text-align: start;
    margin-bottom: 1rem;
}

.item img {
    margin-left: .5rem;
    margin-right: 1rem;
    min-width: 20vw;
}

.item-info {
    margin-right: .5rem;
    margin-left: .5rem;
}

.item-info h3 {
    font-size: 6vw;
    color: #00447E;
}

.item-info p {
    font-size: 4vw;
    color: #3C3C3C;
}

.service-footer p {
    padding: .5rem;
    font-size: 4vw;
    font-family: 'Poppins', sans-serif;
    text-align: center;
}

.service-footer img {
    min-width: 20vw;
}


.service-footer button {
    display: block;
    filter: drop-shadow(0px 4px 4px rgb(0, 0, 0, .25));
    margin-left: auto;
    margin-right: auto;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
    font-weight: bold;
    font-size: 5vw;
    background-color: #fdca02;
    border-radius: 6px;
    border: 0;
    height: 8vh;
    width: 90%;
}

.service-footer button[type=button]:active {
    margin-top: 1.8rem;
    filter: drop-shadow(0px 0px 0px rgb(0, 0, 0));
    margin-bottom: .7rem;
}


@media screen and (min-width: 1001px) {
    .services {
        margin-top: 0rem;
    }

    .services h2 {
        font-size: 4vw;
    }

    .items-container {
        flex-direction: row;
        justify-content: space-around;
    }


    .item {
        display: flex;
        flex-direction: column;
        text-align: center;
        margin-bottom: 1rem;
        width: 90%;
    }

    .item img {
        display: block;
        margin-left: auto;
        margin-right: auto;
        min-width: 10vw;
        margin-bottom: 3vw;
    }

    .item-info h3 {
        font-size: 2.5vw;
    }

    .item-info p {
        font-size: 1.5vw;
    }

    .service-footer p {
        padding: .5rem;
        font-size: 2vw;
        font-weight: 500;
    }

    .service-footer img {
        min-width: 10vw;
    }


    .service-footer button {
        font-size: 2.5vw;
        width: 50%;
        margin-bottom: 5rem;
    }

    .service-footer button[type=button]:active {
        margin-bottom: 4.7rem;
    }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
}

.fullPage {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 16vw;
  font-family: "Roboto", "Poppins", sans-serif;
}

.fullPage .top-bar {
  background-color: #005da9;
  padding: 2.5vw;
  transition: 0.4s;
  position: fixed;
  width: 100%;
  z-index: 99;
  overflow: hidden;
}

.slider-position {
  grid-column: 1/1;
  grid-row: 2/2;
}

.form-position {
  margin-top: 10vw;
  grid-column: 1;
  grid-row: 3/3;
  justify-self: center;
}

.security-position {
  grid-column: 1;
  grid-row: 4/4;
}

.services-position {
  grid-column: 1;
  grid-row: 5/5;
}

@media screen and (min-width: 1001px) {
  .background-brightness {
    position: fixed;
    z-index: 9999999;
    left: 20vw;
    top: 7vw;
  }

  .fullPage {
    grid-template-columns: 30% 70%;
    grid-template-rows: 6vw;
    background-color: #e5e5e5;
  }

  .brightness {
    filter: brightness(0.3);
  }

  .fullPage .top-bar {
    background-color: #005da9;
    padding: 1vw;
    transition: 0.4s;
    position: fixed;
    width: 100%;
    /* height: 10%; */
    z-index: 99;
    overflow: hidden;
    grid-column: 1/2;
    grid-row: 1/1;
  }

  .slider-position {
    grid-column: 1/3;
    grid-row: 2/2;
  }

  .form-position {
    grid-column: 1/3;
    grid-row: 3/3;
    margin-top: 5vw;
    justify-self: center;
  }

  .security-position {
    grid-column: 1/3;
    grid-row: 4/4;
    background-color: #ffffff;
  }

  .services-position {
    margin-top: 3vw;
    grid-column: 1/3;
    grid-row: 5/5;
    background-color: #ffffff;
  }
}

