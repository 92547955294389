/* Contenedor de cada opción */
.selection_radio {
  display: flex;
  align-items: center;
  justify-content: center; /* Centra el contenido */
  gap: 0.5vw; /* Espacio entre el ícono y el texto */
  cursor: pointer;
  font-size: 1vw; /* Reducimos el tamaño del texto */
  font-family: "Poppins", sans-serif;
  height: 4vw; /* Tamaño fijo del botón */
  width: 10vw; /* Ancho fijo del botón */
  min-width: 10vw; /* Asegura un ancho mínimo */
  max-width: 10vw; /* Asegura que no crezca */
  padding: 0 1vw; /* Espaciado interno */
  text-align: center;
  background-color: #FFD300;
  border-radius: 50vw; /* Botón redondeado */
  transition: all 0.3s ease;
  overflow: hidden; /* Evita que el texto sobresalga */
  white-space: nowrap; /* Evita que el texto haga saltos de línea */
  text-overflow: ellipsis; /* Si el texto es muy largo, lo corta */
  margin: 0.5vw;
}

/* Evita que el input afecte el diseño */
.selection_radio input {
  display: none;
}

/* Círculo del radio button */
.checkmark {
  position: relative;
  width: 1.8vw;
  height: 1.8vw;
  background-color: #ffffff;
  border-radius: 50%;
}

/* Cambio de color cuando está seleccionado */
.selection_radio input:checked ~ .checkmark {
  background-color: #fff;
}

/* Indicador dentro del botón */
.checkmark:after {
  content: "";
  display: none;
  position: absolute;
  width: 1.3vw;
  height: 1.3vw;
  border-radius: 50%;
  background: #000;
  top: 0.25vw;
  left: 0.25vw;
}

/* Mostrar el indicador cuando está seleccionado */
.selection_radio input:checked ~ .checkmark:after {
  display: block;
}

/* Ajuste del texto */
.selection_radio span {
  font-size: 0.9vw; /* Reducimos el tamaño del texto */
  font-weight: 600;
  text-align: center;
  white-space: nowrap; /* Evita saltos de línea */
  overflow: hidden; /* Evita que el texto sobresalga */
  text-overflow: ellipsis; /* Si el texto es muy largo, lo corta */
  width: 100%;
}

/* ✅ Asegurar que todos los botones tengan el mismo tamaño */
.selection-container {
  display: flex;
  justify-content: center;
  gap: 1vw;
}

.radio-h3 {
  font-size: 1.3vw; /* Reducir el tamaño de la letra */
  font-weight: 600; /* Ajustar el peso de la fuente */
  margin: 0; /* Eliminar márgenes extra */
  padding: 0;
  text-align: center;
}

/* Responsive - Móviles */
@media screen and (max-width: 768px) {
  .selection_radio {
    font-size: 3vw; /* Reduce el tamaño en móviles */
    height: 8vw;
    width: 12vw; /* Ancho fijo en móviles */
    min-width: 12vw;
    max-width: 12vw;
    padding: 0 2vw;
  }

  .checkmark {
    width: 4vw;
    height: 4vw;
  }

  .checkmark:after {
    width: 2.5vw;
    height: 2.5vw;
    top: 0.75vw;
    left: 0.75vw;
  }

  .selection_radio span {
    font-size: 2vw; /* Reduce aún más el texto en móviles */
  }
}
